import React from 'react';
import { graphql } from 'gatsby';
import BaseLayout from '../components/templates/BaseLayout';
import SEO from '../components/atoms/utility/seo';
import Button from '../components/atoms/inputs/Button';
import FeatureBanner from '../components/molecules/FeatureBanner';
import Video from '../components/video';

const defaultOptionForCommunityFilter = {
  db_ref: 'all',
  name: 'All',
};

const staticSchemaMarkupData = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'flockx',
  logo: 'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  image:
    'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  sameAs: [
    'https://twitter.com/flockxofficial',
    'https://flockx.io',
    'https://www.linkedin.com/company/flockxofficial/',
  ],
  keywords: [
    'sense of belonging',
    'ai sidekick',
    'hidden gems',
    'community app',
    'flockx',
  ].toString(),
  founder: 'Humayun Sheikh',
  slogan: 'Find Your Flock',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '99 Monroe',
    addressLocality: 'Grand Rapids',
    addressRegion: 'MI',
    postalCode: '49503',
    addressCountry: 'USA',
    floor: '2nd Floor',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 30.405504665093982,
    longitude: -97.72119716931535,
  },
};

const SEO_KEYWORDS = [
  'sense of belonging',
  'ai sidekick',
  'community app',
  'hidden gems',
  'nomadic lifestyle',
  'friend finder',
  'flockx',
  'real-time',
];

export default function HomePage({
  data: {
    site: {
      siteMetadata: { description, siteUrl, title },
    },
    heroImageDesktop,
    heroImageMobile,
    sponsorBannerDesktop,
    sponsorBannerMobile,
  },
}) {
  const schemaMarkup = {
    ...staticSchemaMarkupData,
    description,
    url: siteUrl,
  };

  const handleCTAButtonClick = () => {
    window?.gtag('event', 'main_landing_cta_click', {
      event_category: 'CTA',
      event_label: 'Main Landing CTA',
    });

    window.location.href = 'https://community.flockx.io/api/auth/login';
  };

  const handleCTAButton2Click = () => {
    window?.gtag('event', 'main_landing_cta2_click', {
      event_category: 'CTA',
      event_label: 'Main Landing CTA 2',
    });

    window.location.href = 'https://community.flockx.io/api/auth/login';
  };

  return (
    <BaseLayout
      mainSectionClass="z-20 relative flex-grow h-full w-full"
      hideHeroImage
      customHeaderClass=" "
    >

      <SEO
        title={title}
        keywords={SEO_KEYWORDS}
        description={description}
        schemaMarkup={schemaMarkup}
      />

<div className="relative isolate bg-cover bg-center" style={{ backgroundImage: "url('https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1722011212/flockx-website/Landing%20page/Landing-image_dhgeaw.png')" }}>
  <svg
    className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
    aria-hidden="true"
  >
    <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
  </svg>
  <div className="mx-auto max-w-7xl px-6 py-60 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-16">
    <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
      <h1 className="mt-2 max-w-lg text-4xl font-bold tracking-regular text-white sm:text-6xl">
        Real Life Friends Meet <span className='text-white'>AI&nbsp;Sidekicks</span>
      </h1>
      <p className="mt-6 text-lg leading-8 font-regular text-white max-w-lg">
        AI companions that care about you, your friends, and your communities. More adventures, more fun, more time. Introduce your AI Sidekick to your flock today.
      </p>

      <div className="mt-6 flex flex-col lg:flex-row lg:items-center items-left gap-x-6 gap-y-4">
        <div>
          <Button
            label="Connect with Your Sidekick"
            onClickHandler={handleCTAButtonClick}
            id="Connect with Your Sidekick"
          />
        </div>
      </div>

    </div>
    <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow lg:pt-16 hidden lg:block">
      <img
        className="mx-auto w-full max-w-[22.875rem] drop-shadow-xl"
        src="https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1719939259/flockx-website/Landing%20page/Landing-Sidekick-chat_1_gsertf.png"
        alt="Flockx Sidekick Chat"
      />
    </div>
  </div>
</div>



<div className="py-4 sm:py-20"> 
  <div className="mx-auto max-w-7xl px-6 py-6 sm:py-16 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-8">
    <div className="lg:w-1/2 lg:order-2">
      <div className="relative h-80 lg:h-auto lg:w-full">
        <img
          className="h-full w-full object-cover rounded-md"
          src="https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1720028317/flockx-website/Landing%20page/Your-Crews-New-Best-Friends_mqepaq.png"
          alt="Your Crew's New Best Friends Sidekick - kayaking"
        />
      </div>
    </div>
    <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto mt-0 py-8 lg:w-1/2">
      <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-600 sm:text-4xl">
        Your Crew’s New Best Friends
      </h1>
      <p className="mt-6 text-lg leading-8 font-regular text-gray-700 max-w-lg">
        Real-world friendships enhanced with your AI. A night out, a kayaking trip with your roommates, wine tasting with your winos, or a trip to Disney with the family - your Sidekick acts as the planner to get everyone out having fun. As your friends get sidekicks, coordination happens seamlessly as AIs build relationships and find new ways for the flock to have fun.
      </p>
    </div>
  </div>
</div>








<div className="py-4 sm:py-20"> 
  <div className="mx-auto max-w-7xl lg:px-8 xl:justify-end">
    <div className="px-6 lg:flex lg:flex-row-reverse lg:items-center lg:gap-8 mt-0">
      <div className="lg:w-1/2">
        <div className="relative h-80 lg:h-auto lg:w-full">
          <img
            className="h-full w-full object-cover rounded-md lg:hidden"
            src="https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1722011417/flockx-website/Landing%20page/hiking-with-sidekicks_lckmyy.png"
            alt="adventuring with your sidekicks - hiking"
          />
        </div>
        <div className="mb-8 lg:hidden"></div>
        <div className="lg:pl-8">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-600 sm:text-4xl">
            Hobbies Have Never Been <br /> So Much Fun
          </h1>
          <p className="mt-6 text-xl leading-8 text-gray-700">
            Your Sidekick is always on the lookout for activities you care about that are happening near you. Pickup games, watch parties, gatherings, and local gossip are all just a message away. The more you build your AI relationship through real-world activities, the better it gets at sharing your passions.
          </p>
        </div>
      </div>
      <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:w-1/2 lg:pt-0 hidden lg:block">
        <img
          className="mx-auto w-full max-w-[22.875rem] drop-shadow-xl lg:max-w-full rounded-md"
          src="https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1720027720/flockx-website/Landing%20page/hiking-01_2_s2q43r.png"
          alt="Your Crew's New Best Friends Sidekick Chat"
        />
      </div>
    </div>
  </div>
</div>





      
<div className="py-4 pb-6">
  <div className="mx-auto max-w-7xl px-6 py-6 sm:py-16 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-8">
    <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
      <div className="lg:hidden">
        <img
          className="mx-auto w-full max-w-[22.875rem] drop-shadow-xl rounded-md"
          src="https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1720027195/flockx-website/Landing%20page/Grow-your-flock-map_fnzg0o.png"
          alt="grow your flocks map activities"
        />
      </div>
      <div className="mb-8 lg:hidden"></div>
      <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-600 sm:text-4xl">
        Grow Your Flocks <span className="hidden lg:inline"><br /></span> At Home And On The Road
      </h1>
      <p className="mt-6 text-lg leading-8 font-regular text-gray-700 max-w-lg">
        New city, new life milestone, new interests. No matter where your life takes you, your sidekick is always there working for you. Connecting you with people you want to be friends with. Talk to your AI wherever you go, it will find flocks similar to your existing ones and act as your wingman so you never have to deal with icebreakers again.
      </p>
    </div>
    <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow lg:pt-0 hidden lg:block">
      <img
        className="mx-auto w-full max-w-[22.875rem] drop-shadow-xl rounded-md"
        src="https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1720027195/flockx-website/Landing%20page/Grow-your-flock-map_fnzg0o.png"
        alt="Your Crew's New Best Friends Sidekick Chat"
      />
    </div>
  </div>
</div>






        
<div className="py-0 pb-16 sm:py-16">
  <div className="mx-auto max-w-7xl lg:px-8 xl:justify-center">
    <div className="px-6 lg:flex lg:flex-col lg:items-center lg:gap-8">
      <div className="relative h-80 lg:h-auto lg:w-full lg:shrink-0">
        <img
          className="h-full w-full bg-gray-50 object-cover rounded-md"
          src="https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1720024391/flockx-website/Landing%20page/Never-alone-never-miss-out_v0owfa.png"
          alt="AI Sidekicks suggestions, never be alone."
        />
      </div>
      <div className="mb-8 lg:hidden"></div>
      <div className="text-center lg:text-center lg:w-1/2">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-600 sm:text-4xl">
          Never Miss Out<span className="sm:hidden">,<br />Never Alone</span><span className="hidden sm:inline">, Never Alone</span>
        </h1>
        <p className="mt-6 text-xl leading-8 text-gray-700">
          Solo adventuring or just hanging at home, your Sidekick taps into the communities you care about to make sure you never miss out.
        </p>
        <div className="mt-4 flex justify-center">
          <Button
            label="Connect with Your Sidekick"
            onClickHandler={handleCTAButton2Click}
            id="Get-your-Sidekick"
          />
        </div>
      </div>
    </div>
  </div>
</div>






    </BaseLayout>
  );
}

export const query = graphql`
  query ActivityHomePageQuery {
    site {
      siteMetadata {
        description
        siteUrl
      }
    }
    heroImageDesktop: file(name: { eq: "landing-desktop-hero" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    heroImageMobile: file(name: { eq: "landing-mobile-hero" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    sponsorBannerDesktop: file(name: { eq: "sponsors-banner" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    sponsorBannerMobile: file(name: { eq: "sponsors-banner-mobile" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
